<template>
  <div>
    <b-card>

   
    <b-tabs v-model="activeTab">
      <b-tab title="On Campus">
        <b-card>
          <div class="filters-section">
            <b-row>
              <b-col cols="12" md="6" lg="4">
                <label for="">Select University</label>
                <v-select v-model="filters.university_id" :options="universities"
                  :reduce="(option) => option.university_id" label="university_name" @input="onSearch"></v-select>
              </b-col>
              <b-col cols="12" md="6" lg="4">
                <label for="">Select Country</label>
                <v-select v-model="filters.country_id" :options="countries" :reduce="(option) => option.country_id"
                  label="country_name" @input="onSearch"></v-select>
              </b-col>
              <b-col cols="12" md="6" lg="4">
                <label for="">Select City</label>
                <v-select v-model="filters.city_id" :options="cities" :reduce="(option) => option.city_id"
                  label="city_name" @input="onSearch"></v-select>
              </b-col>
            </b-row>
          </div>
          <div>
            <div>
              <!-- <b-container class="py-4"> -->
              <b-row class="py-4">
                <b-col cols="3" class="">
                  <b-card>
                    <b-card-title>Filters</b-card-title>
                    <!-- <div class="range-input">
                      <label for="price-range">Price Range: {{ priceRange[0] + '$' }} - {{ priceRange[1] + '$'
                        }}</label>

                      <input type="range" id="price-range" v-model="priceRange[1]" :min="minPrice" :max="maxPrice"
                        @input="onSearch" />
                    </div> -->
                    <b-form-group label="Property Type">
                      <b-form-checkbox-group class="d-flex flex-column" v-model="filters.type"
                        :options="propertyOptions" @input="onSearch"></b-form-checkbox-group>
                    </b-form-group>
                    <b-form-group label="Amenities">
                      <b-form-checkbox-group class="d-flex flex-column" v-model="filters.facilities"
                        :options="amenityOptions" @input="onSearch"></b-form-checkbox-group>
                    </b-form-group>
                    <b-button variant="outline-primary" block>More filters</b-button>
                  </b-card>
                </b-col>

                <b-col>
                  <div class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mb-3
                      ">
                    <h2>{{ totalListings }}+ stays</h2>
                    <div class="d-flex">
                      <b-form-select v-model="filters.sort" :options="sortOptions" class="mr-2"
                        @input="onSearch"></b-form-select>
                    </div>
                  </div>

                  <b-row>
                    <b-col cols="12" md="6" lg="4" v-for="listing in listings" :key="listing.id">
                      <b-card :img-src="FILESURL + listing.aci_url" img-alt="listing.title" img-top
                        style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;">
                        <div>
                          <div class="d-flex justify-content-start">
                            <b-card-title class="font-weight-bold">{{
                              listing.acc_name
                              }}</b-card-title>
                          </div>
                          <b-card-text>
                            <div class="mt-1">
                              <div>
                                <p class="mb-1">
                                  <feather-icon icon="MapPinIcon" stroke="#000" style="margin-right: 8px" size="20" />{{
                                    listing.acc_address }},<span style="font-weight: 600;">{{ listing.city_name }}</span>
                                </p>
                              </div>
                              <div>
                                <feather-icon icon="BoxIcon" stroke="#000" style="margin-right: 8px" size="20" />
                                {{ listing.acf_names }}
                              </div>
                            </div>
                            <div class="
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  mt-2
                                ">
                              <div class="d-flex align-items-center">
                                <h4 class="font-weight-bold m-0">
                                  ${{ listing.acc_price_per_month }}
                                </h4>
                                <span>/month</span>
                              </div>
                              <b-button size="sm" variant="primary">Book now</b-button>
                            </div>
                          </b-card-text>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>

                  <b-pagination v-model="pagination.currentPage" :total-rows="totalListings"
                    :per-page="pagination.perPage" @change="onFilterChange" class="my-3" align="center"></b-pagination>
                </b-col>
              </b-row>
              <!-- </b-container> -->
            </div>
          </div>
        </b-card>
      </b-tab>
      <b-tab title="Off Campus">
        <div>
          <Accommodations />
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BNavbar,
  BContainer,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BFormInput,
  BFormDatepicker,
  BButton,
  BFormGroup,
  BFormRange,
  BFormCheckboxGroup,
  BFormSelect,
  BPagination,
  BCardText,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import Accommodations from "./Accomodations.vue";
import HomeServices from "@/apiServices/HomeServices";
import vSelect from "vue-select";
import { FILESURL } from "@/config";

export default {
  name: "AccommodationWrapper",
  components: {
    BTab,
    BCard,
    BTabs,
    BRow,
    BCol,
    BNavbar,
    BContainer,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BFormInput,
    BFormDatepicker,
    BButton,
    BFormGroup,
    BFormRange,
    BFormCheckboxGroup,
    BFormSelect,
    BPagination,
    Accommodations,
    vSelect,
    BCardText,
    BCardTitle,
    BCardBody
  },
  data() {
    return {
      activeTab: 0,
      universities: [],
      accommodations: [],
      countries: [],
      states: [],
      cities: [],
      filters: {
        university_id: null,
        country_id: null,
        state_id: null,
        city_id: null,
        type: [],
        facilities: [],
        priceRange: null,
        sort: null,
      },
      location: "",
      date: null,
      guests: 1,
      priceRange: 500,
      propertyType: [],
      amenities: [],
      sortBy: "Recommended",
      pagination: {
        currentPage: 1,
        perPage: 10,
      },
      minPrice: 0,
      maxPrice: 1000,
      priceRange: [0, 200],
      FILESURL,
      totalListings: null,
      propertyOptions: [
        { text: 'Hostel', value: 'hostel', key: 'hostel' },
        { text: 'Apartment', value: 'apartment', key: 'apartment' },
        { text: 'Shared Housing', value: 'shared_housing', key: 'shared_housing' },
        { text: 'Homestay', value: 'homestay', key: 'homestay' },
        { text: 'Hotel', value: 'hotel', key: 'hotel' },
        { text: 'Others', value: 'others', key: 'others' }
      ],
      sortOptions: [
        // { text: "Sort by: Recommended", value: "Recommended" },
        { text: "Price: high to low", value: "asc" },
        { text: "Price: low to high", value: "desc" },
        // { text: "Rating", value: "Rating" },
      ],
      amenityOptions: [
        // { text: 'Electricity', value: 'electricity' },
        { text: 'WiFi', value: 'wifi' },
        // { text: 'Water', value: 'water' },
        { text: 'Parking', value: 'parking' },
        { text: 'Gym', value: 'gym' },
        { text: 'Swimming Pool', value: 'swimming_pool' },
        { text: 'Laundry', value: 'laundry' },
        { text: 'Security', value: 'security' }
      ],
      debounce: null
    };
  },
  methods: {

    onFilterChange() {
      this.$nextTick(() => {
        this.getAllAccommodations();
      });

    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onFilterChange();
      }, 1000);
    },
    async getAllUniversities() {
      try {
        const response = await HomeServices.getAllUniversities();
        if (response.data.status) {
          this.universities = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllUniversities", error);
      }
    },
    async getAllCountries() {
      try {
        const response = await HomeServices.getAllCountries();
        if (response.data.status) {
          this.countries = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllCountries", error);
      }
    },
    async getAllStates() {
      try {
        const response = await HomeServices.getAllStates();
        if (response.data.status) {
          this.states = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllStates", error);
      }
    },
    async getAllCities() {
      try {
        const response = await HomeServices.getAllCities();
        if (response.data.status) {
          this.cities = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllCities", error);
      }
    },
    async getAllAccommodations() {
      try {
        const response = await HomeServices.getAllAccommodations({
          ...this.filters,
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
        });
        if (response.data.status) {
          this.listings = response.data.data.data;
          this.totalListings = response.data.data.total;
        }
      } catch (error) {
        console.log("Error in getAllAccommodations", error);
      }
    },
  },
  beforeMount() {
    this.getAllUniversities();
    this.getAllAccommodations();
    this.getAllCountries();
    this.getAllStates();
    this.getAllCities();
  },
};
</script>

<style scoped>
.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>
